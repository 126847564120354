<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:52
-->
<template>
	<div class="baseVerify">
		<div class="baseVerify-content">
			<div class="hearApplyDetail-btn" style="margin-bottom: 10px;">
				<el-button @click="submitMini" type="primary">批量开具证明并发到送到小程序</el-button>
			</div>
			<div class="activityVerify-table">
				<el-table :data="tableData" border @selection-change="handleSelectionChange" ref="multipleTable">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="faculty" label="学院班级">
					</el-table-column>
					<el-table-column prop="source" label="来源">
					</el-table-column>
					<el-table-column prop="applicationTime" label="申请时间">
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('detail', scope)">详情</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { applyListHandle,agreeHandleApplyBatch } from "@/api/electronic"
	export default {
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				selectedIds : []
			};
		},
		mounted() {
			this.getHandleApplyList()
		},
		methods: {
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						this.$router.push({
							path: `/hearApply/detail?detailId=${scope.row.id}`
						})
						break;
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getHandleApplyList();
			},
			getHandleApplyList() {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				applyListHandle(params).then((res) => {
					if (res.code == 0) {
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			handleSelectionChange(selection) {
				this.selectedIds=selection.map(item => item.id);
				console.log(this.selectedIds);
				
			},
			submitMini() {
				if(this.selectedIds.length>0){
					agreeHandleApplyBatch({ids: this.selectedIds,pass:true}).then((res) => {
						if(res.code == 0) {
							this.$message.success("开具证明并发到送到小程序成功~")
							this.toggleSelection()
						}
					})
				}else{
					this.$message.warning("请选择要操作的数据~")
				}
				
			},
			toggleSelection() {
				this.$refs.multipleTable.clearSelection();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.baseVerify {
		.baseVerify-content {
			padding: 20px 20px;
		}
	}
</style>
