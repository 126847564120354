/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-07-20 17:27:12
 * @LastEditTime: 2022-08-25 11:51:40
 */
import axios from '@/utils/request'
let Api = {
    handleApplyList: "/electronicWorkingHoursRecord/trialList",
    issueHistoryList: "/electronicWorkingHoursRecord/openList",
	// manHourProveList: "/electronicWorkingHoursRecord/workingHoursFileList",
	manHourProveList: "/workingHoursFile/list",
	handleApplyDetail: "/electronicWorkingHoursRecord/",
	handleApplyAgree: "/electronicWorkingHoursRecord/pdf/",
	handleApplyAgreeBatch: "/electronicWorkingHoursRecord/pdfs",
	handleApplyRefuse: "/electronicWorkingHoursRecord/reject/",
	manHourProveDetail: "/electronicWorkingHoursRecord/workingFile/",
}
// 审理申请列表
export function applyListHandle(params) {
	return axios({
		method: 'get',
		url: Api.handleApplyList,
		params: params
	})
}
// 开具记录列表
export function listIssueHistoryList(params) {
	return axios({
		method: 'get',
		url: Api.issueHistoryList,
		params: params
	})
}
// 工时证明文件列表
export function listManHourProve(params) {
	return axios({
		method: 'get',
		url: Api.manHourProveList,
		params: params
	})
}
// 审理申请详情
export function detailHandleApply(id, params) {
	return axios({
		method: 'get',
		url: Api.handleApplyDetail + id,
		params: params
	})
}
// 开具证明并发到送到小程序
export function agreeHandleApply(id, params) {
	return axios({
		method: 'get',
		url: Api.handleApplyAgree + id,
		params: params
	})
}
// 批量开具证明并发到送到小程序
export function agreeHandleApplyBatch(params) {
	return axios({
		method: 'post',
		url: Api.handleApplyAgreeBatch,
		data: params
	})
}
export function refuseHandleApply(id, params) {
	return axios({
		method: 'get',
		url: Api.handleApplyRefuse + id,
		params: params
	})
}

export function detailManHourProve(id, params) {
	return axios({
		method: 'get',
		url: Api.manHourProveDetail + id,
		params: params
	})
}
