/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-07-20 17:27:12
 * @LastEditTime: 2022-08-17 15:46:11
 */
import axios from '@/utils/request'
let Api = {
    upsideOpen: "/workingHours/open",
    manhourStudent: "/workingHours/studentList",
	manhourFillList: "/workingHours/replenishList",
	downLoadManhourFillList: "/workingHours/export",
	ownLoadManhourFillListdetail: "/workingHours/exportDetails",
	studentManhourDetail: "/workingHours/",
	studentDetail: "/studentInformation/",
	volunteerDetail: "/volunteer/information/volunteerId",
	manhourAdd: "/workingHours/",
	manhourEdit: "/workingHours/",
	manhourDetail: "/workingHours/replenishDetails/",
	manhourDelete: "/workingHours/",
	manhourExcelDelete: "/workingHours/workingDeleted/",
}
// 上传通道开启
export function openUpside(params) {
	return axios({
		method: 'post',
		url: Api.upsideOpen,
		params: params
	})
}
// 学生工时列表
export function studentManhour(params) {
	return axios({
		method: 'get',
		url: Api.manhourStudent,
		params: params
	})
}
// 学生工时列表
export function fillManhour(params) {
	return axios({
		method: 'get',
		url: Api.manhourFillList,
		params: params
	})
}
// 学生工时导出
export function manhourFillListDownLoad(params) {
	return axios({
		method: 'get',
		url: Api.downLoadManhourFillList,
		params: params,
		headers: {
		    'X-Requested-With': 'XMLHttpRequest',
		    'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
		
	})
}
// 学生工时明细导出
export function manhourFillDetailDownLoad(params) {
	return axios({
		method: 'get',
		url: Api.ownLoadManhourFillListdetail,
		params: params,
		headers: {
		    'X-Requested-With': 'XMLHttpRequest',
		    'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
		
	})
}
// 学生工时详情
export function detailStudentManhour(id, params) {
	return axios({
		method: 'get',
		url: Api.studentManhourDetail + id,
		params: params
	})
}
// 学生信息
export function detailStudent(id, params) {
	return axios({
		method: 'get',
		url: Api.studentDetail + id,
		params: params
	})
}
// 志愿者信息
export function detailVolunteer(params) {
	return axios({
		method: 'get',
		url: Api.volunteerDetail,
		params: params
	})
}
// 添加工时
export function addManhour(params) {
	return axios({
		method: 'post',
		url: Api.manhourAdd,
		data: params
	})
}
// 编辑工时
export function editManhour(id, params) {
	return axios({
		method: 'put',
		url: Api.manhourEdit + id,
		data: params
	})
}
// 工时详情
export function detailManhour(id, params) {
	return axios({
		method: 'get',
		url: Api.manhourDetail + id,
		params: params
	})
}
// 工时删除
export function deleteManhour(id, params) {
	return axios({
		method: 'delete',
		url: Api.manhourDelete + id,
		params: params
	})
}
// 工时表删除
export function deleteManhourExcel(id, params) {
	return axios({
		method: 'post',
		url: Api.manhourExcelDelete + id,
		params: params
	})
}
